<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">课程使用统计</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">课程名称:</span>
              <el-input
                v-model="ruleForm.courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="组课时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >组课时间:</span
              >
              <el-date-picker
                v-model="ruleForm.startDate"
                type="daterange"
                size="small"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </div>
            <div title="资源提供方" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">资源提供方:</span>
              <el-select
                size="small"
                v-model="ruleForm.providerId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
             <div class="df" style="padding-right: 45px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="getExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                minWidth="180"
                prop="courseName"
              />
              <el-table-column
                label="总课时"
                align="left"
                show-overflow-tooltip
                prop="totalKpoint"
              />
              <el-table-column
                label="其中公共课时"
                align="left"
                show-overflow-tooltip
                prop="publicKpoint"
              ></el-table-column>
              <el-table-column
                label="组课时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="160"
              ></el-table-column>
              <el-table-column
                label="使用班级数量"
                align="left"
                show-overflow-tooltip
                prop="usageProjectNum"
              ></el-table-column>
              <el-table-column
                label="结业学员数量"
                align="left"
                show-overflow-tooltip
                prop="studentNum"
                />
              <el-table-column
                label="资源提供方"
                align="left"
                show-overflow-tooltip
                prop="resourceProvider"
                minWidth="180"
                />
                <el-table-column label="操作" align="center" width="100px" >
                <template slot-scope="scope">
                  <el-button type="text" size="mini" @click="goInfo(scope.row.courseId)">查看班级</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "set/TrajectoryList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      companyList:[],
      // 检索的数据
      ruleForm: {
        courseName: "", // 课程名称
        startDate: [], // 开始时间截止
        providerId:'',
      },
    
    };
  },
  created() {
    this.getTableHeight();
  },
  watch: {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {},
  methods: {
     // 获取 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/getCompanyInfoByName", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
   
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.providerId) {
        params.providerId = this.ruleForm.providerId;
      }
    
      if (this.ruleForm.startDate) {
        params.createTimeStart = this.ruleForm.startDate[0];
        params.createTimeEnd = this.ruleForm.startDate[1];
      }
      this.doFetch({
        url: "/biz/course/usage/statistics",
        params,
        pageNum,
      });
    },
    // 获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 60;
      }
      this.tableHeight = tHeight;
    },
    getExport() {
      const params = {};
      if (this.ruleForm.courseName) {
        params.courseName = this.ruleForm.courseName;
      }
      if (this.ruleForm.providerId) {
        params.providerId = this.ruleForm.providerId;
      }
      if (this.ruleForm.startDate) {
        params.createTimeStart = this.ruleForm.startDate[0];
        params.createTimeEnd = this.ruleForm.startDate[1];
      }
      this.$post("/biz/course/usage/statistics/export", params).then((res) => {
        if (res.status == "0") {
          let list = res.data;
           for (let item of list) {
                console.log(item);
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
      });
    },
    goInfo(courseId){
      this.$router.push({
        path:'/web/operate/courseUsageStatisticsInfo',
        query:{courseId}
      })
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less"></style>
